<template>
  <div>
    <span
      v-if="minimal"
      v-b-tooltip.hover.bottom="value ? 'Изменить ИНН': 'Указать ИНН'"
      class="fa fa-id-card fa-lg text-primary table-action-button"
      @click="showModal = true"
    />
    <b-button
      v-else
      variant="primary"
      class="mr-2"
      @click="showModal = true"
    >
      {{ value ? 'Изменить ИНН': 'Указать ИНН' }}
    </b-button>
    <b-modal
      v-model="showModal"
      class="modal-warning"
      ok-variant="success"
      cancel-title="Отменить"
      ok-title="Сохранить"
      :ok-disabled="$v.innInput.$invalid"
      title="Укажите ИНН для водителя"
      @ok="sendInn()"
    >
      <b-form-group
        class="form-group-el-select"
        label="ИНН"
      >
        <b-form-input
          v-model.trim="$v.innInput.$model"
          @keypress="chkINNField($event)"
          @keyup="replaceINNField($event)"
          :state="chkState('innInput')"
        />
        <b-form-invalid-feedback id="innLiveFeedback7">
          <span v-if="!$v.innInput.required">Это поле обязательно для заполнения</span>
          <span v-if="!$v.innInput.minLength || !$v.innInput.maxLength">
            Инн должен содержать 12 символов
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {contractorDriverAddInn} from '@/services/api';
import notifications from '@/components/mixins/notifications';
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

export default {
  name: 'DriverInnEdit',
  mixins: [validationMixin, notifications],
  props: {
    driverId: [Number, String],
    contractorId: [Number, String],
    value: String,
    minimal: {type: Boolean, default: false}
  },
  data() {
    return {
      showModal: false,
      innInput: this.value,
    };
  },
  methods: {
    async sendInn() {
      const response = await contractorDriverAddInn(this.$store.state.user.ownerId, this.driverId, this.innInput);
      // const response = await contractorDriverAddInn(this.contractorId, this.driverId, this.innInput);
      if (response && response.status === 200) {
        this.showSuccess('ИНН установлен');
        this.$emit('input', this.innInput);
      }
    },
    chkINNField(e) {
      if (e.currentTarget.value.length >= 12) {
        e.preventDefault();
      };
    },
    replaceINNField(e) {
      this.innInput = e.currentTarget.value.replace(/[^0-9]/g, '');
    },
    chkState(val) {
      if (val.indexOf('.') !== -1) {
        const parameters = val.split('.');
        const field = this.$v[parameters[0]][parameters[1]];
        if (!field.$dirty) {
          return null;
        } else return !field.$invalid;
      } else {
        const field = this.$v[val];

        if (!field.$dirty) {
          return null;
        } else return !field.$invalid;
      }
    },
  },
  validations: {
    innInput: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12),
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.innInput = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>

</style>
